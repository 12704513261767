<style>

/* Create three unequal columns that floats next to each other */
.column {
  float: left;
  padding: 10px;
  width: 24.00%;
}

.left, .right {
  width: 25%;
}

.middle {
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

ul {
    list-style: none;
}

.grid .result-row {
    margin-left: 1em;
}

.grid .result-row {
    border-radius: 5px;
    display: -moz-inline-stack;
    display: inline-block;
    box-shadow: 0 0 7px 0 #ddd;
    border: 1px solid #ccc;
    line-height: 1.3em;
    max-height: 290px;
    margin: 4px 0 12px 2.5%;
    overflow: hidden;
    padding: 0 0 10px 0;
    position: relative;
    width: 95%;
    white-space: normal;
    vertical-align: top;
}

.grid .result-row .result-image {
    border-radius: 5px 5px 0 0;
    background-color: transparent;
    display: block;
    height: 225px;
    position: relative;
}
.result-row .result-image {
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    display: none;
}
a:hover {
    text-decoration: underline;
}
.gallery {
    position: relative;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
}

.grid .result-info {
    margin-left: 5px;
    margin-top: 2px;
    white-space: normal;
}
.result-info {
    font-family: "Times New Roman",Times,serif;
    line-height: 20px;
    margin: 0;
    text-overflow: ellipsis;
}

.result-row .icon-star {
    float: left;
    margin: 2px 5px 0 0;
}
.icon-star {
    background-position: 0 -96px;
    margin-top: -4px;
}
.icon {
    display: -moz-inline-stack;
    display: inline-block;
    cursor: pointer;
    height: 16px;
    vertical-align: middle;
    width: 16px;
}

.screen-reader-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.grid .result-row {
    border-radius: 5px;
    display: -moz-inline-stack;
    display: inline-block;
    box-shadow: 0 0 7px 0 #ddd;
    border: 1px solid #ccc;
    line-height: 1.3em;
    max-height: 329px;
    margin: 4px 0 12px 2.5%;
    overflow: hidden;
    padding: 0 0 10px 0;
    position: relative;
    width: 95%;
    white-space: normal;
    vertical-align: top;
}

</style>
<template>
    <main>
        <div class="container mx-3">
            <div class="row">
                <div class="column">
                    <a
                       v-on:click='test(1)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >atlanta-falcons</a
                    >
                </div>

                <div class="column">
                    <a
                        v-on:click='test(2)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >baltimore-revens</a
                    >
                </div>

                <div class="column">
                    <a
                         v-on:click='test(3)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >carolina-panthers</a
                    >
                </div>
            
                <div class="column">
                    <a
                        v-on:click='test(4)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >chicago-bears</a
                    >
                </div>
            </div>
            <div class="row">
            
                <div class="column">
                     <a
                         v-on:click='test(5)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >cincinnati-bengals</a
                    >
                </div>

                <div class="column">
                    <a
                         v-on:click='test(6)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >cleveland-browns</a
                    >
                </div>

                <div class="column">
                    <a
                         v-on:click='test(7)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >dallas-cowboys</a
                    >
                </div>

                <div class="column">
                    <a
                         v-on:click='test(8)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >houston-texans</a
                    >
                </div>
            </div>
            <div class="row">
            
                <div class="column">
                    <a
                         v-on:click='test(9)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >las-vegas-raiders</a
                    >
                </div>
            
                <div class="column">
                    <a
                        v-on:click='test(10)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >minnesota-vikings</a
                    >
                </div>
            
                <div class="column">
                    <a
                        v-on:click='test(11)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >new-york-giants</a
                    >
                </div>
             
                <div class="column">
                    <a
                        v-on:click='test(12)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >new-york-jets</a
                    >
                </div>
            </div>
            <div class="row">

                 <div class="column">
                    <a
                        v-on:click='test(13)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >la-chargers-la-rams</a
                    >
                </div>

                <div class="column">
                    <a
                        v-on:click='test(14)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >philadelphia-eagles</a
                    >
                </div>

                <div class="column">
                    <a
                        v-on:click='test(15)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >pittsburgh-steelers</a
                    >
                </div>
             
                <div class="column">
                    <a
                        v-on:click='test(16)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >san-francisco-49ers</a
                    >
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <a
                        v-on:click='test(17)'
                        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
                        >seattle-seahawks</a
                    >
                </div>
            
               
            </div>
            
        </div>
        <div>
            <ul id="text"></ul>
        </div>
    </main>
</template>

<script>
import axios from "axios";

export default {
    methods:
    {
        test:function (w)
        {
            // console.log("oo");
            if(w==1)
            {           
                axios.get("http://localhost/shiv/")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==2)
            {
                axios.get("http://localhost/shiv/index-2.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==3)
            {
                axios.get("http://localhost/shiv/index-3.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==4)
            {
                axios.get("http://localhost/shiv/index-4.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==5)
            {
                axios.get("http://localhost/shiv/index-5.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==6)
            {
                axios.get("http://localhost/shiv/index-6.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==7)
            {
                axios.get("http://localhost/shiv/index-7.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==8)
            {
                axios.get("http://localhost/shiv/index-8.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }else if(w==9)
            {
                axios.get("http://localhost/shiv/index-9.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==10)
            {
                axios.get("http://localhost/shiv/index-10.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==11)
            {
                axios.get("http://localhost/shiv/index-11.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==12)
            {
                axios.get("http://localhost/shiv/index-12.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==13)
            {
                axios.get("http://localhost/shiv/index-13.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==14)
            {
                axios.get("http://localhost/shiv/index-14.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==15)
            {
                axios.get("http://localhost/shiv/index-15.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==16)
            {
                axios.get("http://localhost/shiv/index-16.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
            else if(w==17)
            {
                axios.get("http://localhost/shiv/index-17.php")
                .then(response =>document.getElementById('text').innerHTML =response.data.user)
            }
        }
    }
};
</script>


<!-- <script>
import axios from "axios";
    export default {
        mounted() {
            document.getElementById('text').innerHTML =response.data.user
        },
        data() {
            return {
              name: '',
              description: '',
              output: ''
            };
        },
        methods: {
            test:function () {
                e.preventDefault();
                let currentObj = this;
                this.axios.get("http://localhost/shiv/")
                .then(response) =>document.getElementById('text').innerHTML =response.data.user)
            }
        }
    }
</script> -->




